<template>
  <div class="card bg-light p-2">
    <h5>ABN Details</h5>
    <div class="row row-cols-xl-3 row-cols-md-2 row-cols-1">
      <info-line
        heading="h6"
        label="Registered name"
        :value="abn.name"
        class="col"
      />
      <info-line
        heading="h6"
        label="ABN status"
        :value="abn.status"
        class="col"
      />
      <info-line heading="h6" label="Registered for GST" class="col">
        {{ yesNo(abn.gst) }}
      </info-line>
      <info-line heading="h6" label="DGR endorsed" class="col">
        {{ yesNo(abn.dgrEndorsed) }}
      </info-line>
      <info-line heading="h6" label="Charity type" class="col">
        {{ emptyFill(abn.atoCharityType) }}
      </info-line>
      <info-line heading="h6" label="ACNC registration" class="col">
        {{ emptyFill(abn.acncRegistration) }}
      </info-line>
      <info-line heading="h6" label="Location" class="col">
        {{ abn.state }}, {{ abn.postcode }}
      </info-line>
      <info-line heading="h6" label="Tax concessions" class="col">
        <ul
          class="list-unstyled p-0"
          v-if="abn.taxConcessions && abn.taxConcessions.length > 0"
        >
          <li v-for="concession in abn.taxConcessions" :key="concession">
            {{ concession }}
          </li>
        </ul>
        <div v-else>-</div>
      </info-line>
    </div>
  </div>
</template>

<script>
import InfoLine from "../interface/InfoLine";
import {defineComponent} from "vue";
export default defineComponent({
  name: "AbnDetails",
  props: ["abn"],
  components: {InfoLine},
  computed: {

  },
  methods: {
    yesNo(value) {
      return value ? "Yes" : "No"
    } ,
    emptyFill(value) {
      return value ? value : "-"
    },
  }
});
</script>
