<template>
  <div class="pb-3">
    <b-card
      title="Congratulations! Your organisation has been created."
      border-variant=""
      bg-variant="light"
    >
      <p>
        You can now complete your organisation’s details below. You might also
        like to try:
      </p>
      <ul>
        <li><strong>Adding users</strong> to your organisation</li>
        <li>
          Setting <strong>user roles</strong> so that you can manage what each
          user can do
        </li>
        <li>
          <strong>Manage previous SmartyGrants submissions</strong> under the
          ‘My Submissions’ area – e.g. assign any submissions that belong to
          this organisation so others can share access
        </li>
        <li>
          <strong>Clean up</strong> your past submissions – e.g. delete any
          unsubmitted/draft submissions you started incorrectly or no longer
          need to store
        </li>
        <li>
          <strong>Store files</strong> to access when completing SmartyGrants
          forms
        </li>
      </ul>
      If you need any help with the above, please visit
      <a href="https://applicanthelp.smartygrants.com.au/smartyfile/"
        >SmartyFile help.</a
      >
    </b-card>
  </div>
</template>

<script>
export default {
  name: "OrgSetupSuccess",
};
</script>
