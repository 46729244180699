<template>
  <div>
    <div v-for="(bankaccount, index) in org.bankAccounts" :key="bankaccount.id">
      <bank-acct-dtl-view
        :bankaccount="bankaccount"
        :index="index"
        :is-nzbn="org.nzbn != null"
      />
    </div>
  </div>
</template>

<script>
import BankAcctDtlView from "./BankAcctDtlView";

export default {
  name: "TaggedList",
  components: {
    BankAcctDtlView,
  },
  props: ["org", "keyName"],
};
</script>

<style scoped></style>
