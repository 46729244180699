<template>
  <!-- Read view of Bank Accounts -->
  <div>
    <div class="row bank_acct_row">
      <span class="bank_acct_margin bank_acct_nickname" v-if="nickName !== ''">
        {{ nickName }}</span
      >
      <span class="bank_acct_margin"> {{ bankaccount.name }}</span>
      <div v-if="isNzbn">
        {{ bankaccount.bankCode }} {{ bankaccount.branchCode }}
        {{ bankaccount.accountNumberBody }}
        {{ bankaccount.accountNumberSuffix }}
      </div>
      <div v-else>
        <span class="bank_acct_margin">{{ bsb(bankaccount.bsb) }} </span>
        <span class="bank_acct_margin">/</span>
        {{ bankacct(bankaccount.accountNumber) }}
      </div>
    </div>
  </div>
</template>

<script>
import {bsb} from "@/filters/bsb";
import {bankacct} from "@/filters/bankacct";

export default {
  name: "BankAcctDtlView",
  props: ["bankaccount", "index", "isNzbn"],
  methods: {bankacct, bsb},
  computed: {
    nickName() {
      if (this.bankaccount && this.bankaccount.nickname !== "") {
        return this.bankaccount.nickname + ":";
      } else return "";
    },
  },
};
</script>

<style scoped>
.bank_acct_row {
  margin-left: 0.2rem;
  margin-top: 0.1rem;
}
.bank_acct_margin {
  margin-right: 0.7rem;
}
.bank_acct_nickname {
  font-weight: 600;
}
</style>
