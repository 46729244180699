<template>
  <div>
    <div class="form-group pt-2">
      <label>{{ this.label }}</label>
      <div class="inline-form">
        <b-form-input
          id="registration"
          v-model="form"
          class="of-input"
          :state="isValid"
          @change="onChangeRegistration"
          v-on:keyup.enter="lookup"
        />
        <b-button variant="outline-primary" v-on:click="lookup">
          <b-icon icon="search" />
          Lookup
        </b-button>
      </div>
      <div class="d-flex align-items-center mb-3" v-if="isSearching">
        <b-spinner variant="primary" small label="searching" class="mr-2" />
        Validating...
      </div>
    </div>
    <abn-details v-if="abnDetails" :abn="abnDetails" />
    <nzbn-details v-if="nzbnDetails" :nzbn="nzbnDetails" />
  </div>
</template>

<script>
import {mapActions} from "vuex";
import AbnDetails from "@/components/org/AbnDetails";
import NzbnDetails from "@/components/org/NzbnDetails";
import handleApiError from "../../shared/apiErrorUtil";

export default {
  name: "Lookup",
  props: ["org", "label", "editOrg"],
  components: {AbnDetails, NzbnDetails},
  data() {
    return {
      isSearching: false,
      isValid: null,
      form: "",
      abnDetails: null,
      nzbnDetails: null,
      currentOrg: {
        abn: this.org.abn,
        nzbn: this.org.nzbn,
      },
    };
  },
  computed: {
    cleanRegistration() {
      return this.cleanReg(this.form);
    },
    abnOrNzbn() {
      return this.abnDetails ? "ABN" : "NZBN";
    },
  },
  mounted() {
    const regNumber = this.currentOrg.abn || this.currentOrg.nzbn;
    if (regNumber) {
      this.form = regNumber;
      this.lookup();
    }
  },
  methods: {
    onChangeRegistration() {
      this.isValid = false;
      this.$emit("valid-org", this.isValid);
    },
    lookup() {
      this.isSearching = true;
      this.isValid = null;
      this.duplicateOrgs = [];
      this.abnDetails = null;
      this.nzbnDetails = null;
      this.$emit("reset-lookup");

      if (!this.isFormatValid(this.cleanRegistration)) {
        this.isSearching = false;
        this.isValid = false;
        this.$emit("valid-org", this.isValid);
        return false;
      }

      this.getRegistryDetails(this.cleanRegistration);
    },
    getRegistryDetails(reg) {
      const org = this.currentOrg;
      let detailsPromise;
      const isAbn = this.isAbn(reg);
      if (isAbn) {
        org.abn = this.form;
        org.nzbn = null;
        detailsPromise = this.getAbnDetails(reg).then(details => {
          this.isValid = true;
          this.abnDetails = details;
          return true;
        });
      } else if (this.isNzbn(reg)) {
        org.nzbn = this.form;
        org.abn = null;
        detailsPromise = this.getNzbnDetails(reg).then(details => {
          this.isValid = true;
          this.nzbnDetails = details;
          return true;
        });
      } else {
        // This case should not be possible
        this.isSearching = false;
      }
      if (detailsPromise) {
        detailsPromise
          .then(this.isOrgUnique)
          .then(() => {
            this.createOrg(org.abn, org.nzbn);
          })
          .catch(error => {
            this.isValid = false;
            const status = error.status || error.response.status;
            if (status >= 500) {
              const msg = isAbn
                ? "ABN registry is experiencing some issues so we can't verify your ABN. Please try again later."
                : "NZBN registry is experiencing some issues so we can't verify your NZBN. Please try again later.";
              handleApiError(error, this, msg);
            } else {
              handleApiError(error, this);
            }
          })
          .finally(() => {
            this.isSearching = false;
            this.$emit("valid-org", this.isValid);
          });
      }
    },
    cleanReg(reg) {
      // Remove inner whitespaces that may be put as formatting
      return reg === null ? null : reg.replace(/\s/g, "");
    },
    isAbnFormatValid(abnDigits) {
      abnDigits[0]--;
      const weightArray = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];
      let value = 0;
      for (let i = 0; i < weightArray.length; i++) {
        value += abnDigits[i] * weightArray[i];
      }
      return value % 89 === 0;
    },
    isFormatValid(reg) {
      if (reg == null) {
        return false;
      }
      const regDigits = reg.split("").map(Number);
      if (this.isAbn(regDigits)) {
        return this.isAbnFormatValid(regDigits);
      } else if (this.isNzbn(regDigits)) {
        return this.isNzbnFormatValid(regDigits);
      } else {
        return false;
      }
    },
    isOrgUnique() {
      let orgsPromise;
      const reg = this.cleanRegistration;
      if (this.isAbn(reg)) {
        if (this.editOrg && reg === this.cleanReg(this.org.abn)) {
          return true; //edit org - abn unchanged;
        }
        orgsPromise = this.findOrgsByAbn(this.currentOrg.abn);
      } else {
        if (this.editOrg && reg === this.cleanReg(this.org.nzbn)) {
          return true; //edit org - nzbn unchanged;
        }
        orgsPromise = this.findOrgsByNzbn(this.currentOrg.nzbn);
      }
      return orgsPromise
        .then(orgs => {
          if (orgs.length > 0) {
            this.$emit("duplicate-orgs", orgs);
          }
          return orgs.length === 0;
        })
        .catch(() => true);
    },
    isNzbnFormatValid(nzbnDigits) {
      const weightArray = [1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3];
      const checkDigit = nzbnDigits[12];
      let value = 0;
      for (let i = 0; i < weightArray.length; i++) {
        value += nzbnDigits[i] * weightArray[i];
      }
      const mod = value % 10;
      const calculatedDigit = (10 - mod) % 10; // This second mod is for the case when mod is 0
      return calculatedDigit === checkDigit;
    },
    isAbn(abn) {
      return abn.length === 11;
    },
    isNzbn(nzbn) {
      return nzbn.length === 13;
    },
    createOrg(abn, nzbn) {
      this.$emit("create-org", !this.editOrg, abn, nzbn);
    },
    ...mapActions("orgStore", ["findOrgsByAbn", "findOrgsByNzbn"]),
    ...mapActions("abnStore", ["getAbnDetails"]),
    ...mapActions("nzbnStore", ["getNzbnDetails"]),
  },
};
</script>

<style lang="scss" scoped>
@import "../../../content/scss/bootstrap-variables";
.inline-form {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-right: -1em;
  margin-bottom: -0.8em;
  > * {
    margin-bottom: 0.8em;
    margin-right: 1em;
    flex-shrink: 0;
  }
  .of-input {
    width: auto;
    flex-grow: 1;
  }
}
</style>
