<template>
  <div>
    <div
      v-for="(bankaccount, index) in copyOrg.bankAccounts"
      :key="bankaccount.id"
    >
      <bank-acct-input
        ref="bankacctinput"
        class="bankacctinput"
        v-model="copyOrg.bankAccounts[index]"
        :bankaccountlist="copyOrg.bankAccounts"
        :index="index"
        :is-nzbn="org.nzbn != null"
        @remove="remove"
      />
    </div>
    <div class="d-flex mb-4">
      <button
        class="btn btn-link pl-0 focus:outline-none"
        v-on:click="addRow"
        type="button"
        aria-disabled="true"
      >
        Add another...
      </button>
    </div>
  </div>
</template>

<script>
import BankAcctInput from "@/components/interface/BankAcctInput";
import handleApiError from "../../shared/apiErrorUtil";
import {mapActions} from "vuex";

export default {
  name: "TaggedList",
  components: {
    BankAcctInput,
  },
  props: ["org"],
  data() {
    return {
      copyOrg: {
        id: this.org.id,
        bankAccounts: this.deepCopy(this.org.bankAccounts),
      },
      defaultBankAcct: {
        nickname: "",
        name: "",
        bsb: "",
        accountNumber: "",
        bankCode: "",
        branchCode: "",
        accountNumberBody: "",
        accountNumberSuffix: "",
      },
    };
  },
  methods: {
    ...mapActions("orgStore", ["patchOrg"]),
    addRow(e) {
      if (e) {
        e.target.blur();
      }
      this.copyOrg.bankAccounts.push(Object.assign({}, this.defaultBankAcct));
    },
    clearEmptyData() {
      const filteredData = this.copyOrg.bankAccounts.filter((entry, index) => {
        if (index > 0) {
          if (this.org.abn != null) {
            return !(
              entry.nickname === "" &&
              entry.name === "" &&
              entry.bsb === "" &&
              entry.accountNumber === ""
            );
          } else {
            return !(
              entry.nickname === "" &&
              entry.name === "" &&
              entry.bsb === "" &&
              entry.bankCode === "" &&
              entry.branchCode === "" &&
              entry.accountNumberBody === "" &&
              entry.accountNumberSuffix === ""
            );
          }
        } else {
          return true; //first entry
        }
      });
      this.copyOrg.bankAccounts.splice(
        0,
        this.copyOrg.bankAccounts.length,
        ...filteredData
      );
    },
    checkErrors() {
      const errors = Object.values(this.$refs.bankacctinput).filter(
        ref => !ref.isValid()
      );
      return errors.length > 0;
    },
    deepCopy(source) {
      return source.map(element => ({...element}));
    },
    remove(bankAcct) {
      this.copyOrg.bankAccounts = this.copyOrg.bankAccounts.filter(
        element => element !== bankAcct
      );
    },
    save() {
      // First clear fully empty rows
      this.clearEmptyData();
      if (this.checkErrors()) {
        return Promise.reject(
          "Errors detected when saving bank account details"
        );
      }
      return this.patchOrg(this.copyOrg)
        .then(() => "Contacts Updated")
        .catch(err => {
          handleApiError(err, this);
          throw err;
        });
    },
  },
  mounted() {
    if (this.copyOrg.bankAccounts.length === 0) {
      this.addRow(null);
    }
  },
};
</script>

<style scoped>
.bankacctinput {
  margin-top: 0.8rem;
}
</style>
