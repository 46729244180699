<template>
  <div>
    <join-requests
      v-if="isAdminOf()(orgId)"
      :organisation-id="orgId"
      ref="join"
      v-on:new-member="onMemberUpdated"
    />
    <pending-members
      v-if="isAdminOf()(orgId)"
      :organisation-id="orgId"
      ref="pending"
      v-on:show-role-legend="showLegend"
      v-on:show-access-legend="showAccessLegend"
      v-on:change-role="changeRole"
      v-on:remove-role="removeRole"
    />
    <div
      v-if="
        ($refs.join && $refs.join.hasRequests) ||
        ($refs.pending && $refs.pending.showPendingMembers)
      "
    >
      The following users are part of your organisation
    </div>
    <div class="restrictions-info" v-if="!isAdminOf()(orgId)">
      Due to your role within the organisation, you can only see a list of Administrator users.
    </div>
    <b-table
      :items="usersProvider"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      primary-key="email"
      sort-by="name"
      stacked="md"
      striped
      sort-icon-left
      id="members"
    >
      <template v-slot:head(group)>
        <div class="d-flex align-items-end">
          <span>Role</span>
          <clickable-icon
            class="btn-xs btn-tip ml-1 mb-1"
            icon="info-circle"
            srLabel="Role Legend"
            variant="primary"
            :scale="1.5"
            @click="showLegend"
          />
        </div>
      </template>
      <template v-slot:head(fullAccess)>
        <div class="d-flex align-items-end">
          <span>Submission Access</span>
          <clickable-icon
            class="btn-xs btn-tip ml-1 mb-1"
            icon="info-circle"
            srLabel="Access Legend"
            variant="primary"
            :scale="1.5"
            @click="showAccessLegend"
          />
        </div>
      </template>
      <template
        v-for="field in dynamicFields(fields)"
        v-slot:[`head(${field.key})`]
      >
        {{ field.label }}
      </template>
      <template v-slot:cell(canSubmit)="data">
        <check-icon :show="data.item.canSubmit" />
      </template>
      <template v-slot:cell(actions)="data" v-if="isAdminOf()(orgId)">
        <clickable-icon
          @click="changeRole(data.item)"
          icon="pencil"
          sr-label="Change Role"
          class="pl-1 pr-1"
        />
        <clickable-icon
          @click="removeRole(data.item)"
          icon="x-circle"
          variant="danger"
          sr-label="Remove User"
          class="pl-1"
          :enabled="isNotCurrentUser(data.item)"
        />
      </template>
    </b-table>
    <Pagination
      :number-of-elements="numberOfElements"
      :total-rows="totalRows"
      :current-page="currentPage"
      :per-page="perPage"
      @update-current-page="updateCurrentPage"
    />
    <b-button
      v-if="isAdminOf()(orgId)"
      @click="changeRole(null)"
      variant="primary"
      >Add User</b-button
    >
    <MemberUpdate
      v-if="isAdminOf()(orgId)"
      modal-id="member"
      :organisation-id="orgId"
      :member="selectedMember"
      v-on:member-updated="onMemberUpdated"
    />
    <RoleLegendModal modal-id="rolelegend" :org-id="orgId" />
    <AccessLegendModal modal-id="accesslegend" />
  </div>
</template>

<script>
import MemberUpdate from "./MemberUpdate";
import RoleLegendModal from "../role/RoleLegendModal";
import AccessLegendModal from "./AccessLegendModal";
import {mapActions, mapGetters} from "vuex";
import JoinRequests from "../joinRequest/JoinRequests";
import ClickableIcon from "../interface/ClickableIcon";
import CheckIcon from "../interface/CheckIcon";
import Pagination from "../interface/Pagination";
import handleApiError from "../../shared/apiErrorUtil";
import PendingMembers from "@/components/org/PendingMembers";
import {formatDate} from "@/shared/filter";

export default {
  name: "Members",
  components: {
    CheckIcon,
    ClickableIcon,
    JoinRequests,
    MemberUpdate,
    Pagination,
    RoleLegendModal,
    AccessLegendModal,
    PendingMembers,
  },
  props: ["orgId", "members"],
  data() {
    return {
      selectedMember: null,
      users: [],
      fields: [
        {key: "name", sortable: true},
        {key: "email", sortable: true},
        {
          key: "joinDate",
          label: "Date Joined",
          sortable: true,
          formatter: formatDate,
        },
        {
          key: "group",
          label: "Role",
          formatter: this.displayGroups,
          sortable: true,
        },
        {
          key: "fullAccess",
          label: "Submission Access",
          formatter: fullAccess => (fullAccess ? "All" : "Limited"),
          class: "force-wrap-7",
          sortable: true,
        },
        {
          key: "canSubmit",
          label: "Can Submit",
          class: "text-center force-wrap-5",
          sortable: true,
        },
      ],
      currentPage: 1,
      perPage: 15,
      totalRows: 0,
      numberOfElements: 0,
    };
  },
  methods: {
    ...mapActions("orgStore", ["fetchMembersPage", "removeMember"]),
    ...mapGetters("auth", ["isAdminOf", "getCurrentUser"]),
    ...mapGetters("orgStore", ["getOrgById"]),
    ...mapGetters("role", ["getNameForGroup"]),
    onMemberUpdated() {
      this.$root.$emit("bv::refresh::table", "members");
      this.$root.$emit("bv::refresh::table", "pendingMembers"); //refresh PendingMembers
    },
    updateCurrentPage(currentPage) {
      this.currentPage = currentPage;
    },
    usersProvider(ctx) {
      const sortDir = ctx.sortDesc ? "desc" : "asc";
      const pageRequest = {
        orgId: this.orgId,
        query: {
          params: {
            page: ctx.currentPage - 1,
            size: ctx.perPage,
            sort: ctx.sortBy + "," + sortDir,
          },
        },
      };
      return this.fetchMembersPage(pageRequest)
        .then(response => {
          const data = response.data;
          this.totalRows = data.totalElements;
          this.numberOfElements = data.numberOfElements;
          return data.content;
        })
        .catch(error => handleApiError(error, this));
    },
    isNotCurrentUser(member) {
      return member.email !== this.getCurrentUser().email;
    },
    changeRole(member) {
      this.selectedMember = member;
      this.$bvModal.show("member");
    },
    removeRole(member) {
      if (member.email === this.getCurrentUser().email) {
        const message = "You cannot remove yourself from an organisation.";
        this.$bvModal.msgBoxOk(message, {title: "You cannot remove yourself"});
      } else {
        const message =
          member.name +
          " will be removed from your organisation and they will no longer have access to " +
          "items across the organisation. Are you sure you wish to proceed?";
        this.$bvModal
          .msgBoxConfirm(message, {title: "Are you sure?"})
          .then(response => {
            if (response) {
              this.removeMember({
                orgId: this.orgId,
                email: member.email,
              })
                .then(() => {
                  if (this.numberOfElements === 1) {
                    this.updateCurrentPage(this.currentPage - 1);
                  } else {
                    this.onMemberUpdated();
                  }
                })
                .catch(error => handleApiError(error, this));
            }
          });
      }
    },
    displayGroups(group) {
      if (!group || group === "SUBMITTER") {
        return "";
      }
      return this.getNameForGroup()(group);
    },
    showLegend() {
      this.$bvModal.show("rolelegend");
    },
    showAccessLegend() {
      this.$bvModal.show("accesslegend");
    },
    dynamicFields(fields) {
      if (
        this.isAdminOf()(this.orgId) &&
        fields.filter(f => f.key === "actions").length === 0
      ) {
        fields.push({key: "actions", class: "text-right"});
      }
    },
  },
};
</script>
<style scoped lang="scss">
.disappear-leave-to {
  transition: all 0.5s ease-in-out;
  transform: scaleX(0) scaleY(0);
  opacity: 0;
}

.tight {
  margin-left: -0.1em;
  margin-bottom: 0.2em;
}

::v-deep .force-wrap-7 {
  max-width: 7em;
}

::v-deep .force-wrap-5 {
  max-width: 5em;
}

.btn.btn-xs {
  font-size: x-small;
  z-index: 999;
}

.btn.btn-tip:focus {
  box-shadow: none;
}

$screen-md-width: 768px;
::v-deep .b-table-stacked-md {
  @media (max-width: $screen-md-width) {
    .force-wrap-7 {
      max-width: none;
    }
    .force-wrap-5 {
      max-width: none;
    }
  }
}
</style>
