<template>
  <div class="card bg-light p-2">
    <h5>NZBN Details</h5>
    <div class="row row-cols-xl-3 row-cols-md-2 row-cols-1">
      <info-line
        heading="h6"
        label="Registered Name"
        :value="nzbn.name"
        class="col"
      />
      <info-line
        heading="h6"
        label="Registration Date"
        :value="nzbn.registrationDate"
        class="col"
      />
      <info-line
        heading="h6"
        label="Entity Status"
        :value="nzbn.entityStatus"
        class="col"
      />
      <info-line
        heading="h6"
        label="Entity Type"
        :value="nzbn.entityType"
        class="col"
      />
      <info-line
        heading="h6"
        label="Registered Address"
        v-if="nzbn.registeredAddress"
        class="col"
      >
        {{ emptyFill(this.singleLineAddress(nzbn.registeredAddress)) }}
      </info-line>
      <info-line heading="h6" label="Registered Address" v-else class="col">
        {{ emptyFill(nzbn.registeredAddress) }}
      </info-line>
      <info-line
        heading="h6"
        label="Office Address"
        v-if="nzbn.officeAddress"
        class="col"
      >
        {{ emptyFill((nzbn.officeAddress)) }}
      </info-line>
      <info-line heading="h6" label="Office Address" v-else class="col">
        {{ emptyFill(nzbn.officeAddress) }}
      </info-line>
    </div>
  </div>
</template>

<script>
import InfoLine from "../interface/InfoLine";
import {dataMapper} from "@/utils/dataMapper";
export default {
  name: "NzbnDetails",
  props: ["nzbn"],
  components: {InfoLine},
  methods: {
    emptyFill: value => (value ? value : "-"),
    singleLineAddress(address) {
      return dataMapper.singleLineAddress(address);
    },
  },
};
</script>
