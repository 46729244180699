<template>
  <b-modal
    :id="modalId"
    size="xl"
    @hidden="resetModal"
    @show="initialiseMember(member)"
    :title="title"
  >
    <template v-slot:default>
      <form name="memberForm" role="form" class="mt-0 mb-0" v-on:submit.prevent>
        <div v-if="member" class="mb-3">
          Edit user role and permission for
          <strong>{{ member.name }} ({{ member.email }})</strong>
        </div>
        <b-form-group v-else label="Email" label-for="email" label-cols="2">
          <div>
            <b-form-input
              id="email"
              v-model="$v.email.$model"
              :state="isValidEmail"
              @focus="emailFocus"
              debounce="500"
              v-on:keyup.enter="save"
            />
            <b-form-invalid-feedback :state="isValidEmail">
              <span v-if="!isNewMember"
                >This user is already part of this organisation.</span
              >
              <span v-else>Please enter a valid email address.</span>
            </b-form-invalid-feedback>
          </div>
        </b-form-group>
        <div class="alert alert-warning offset-2 col-10" v-if="isUserNotFound">
          <h6>User not found.</h6>
          The email provided is not registered with SmartyGrants. You may
          register them now.
        </div>
        <b-form-group
          v-if="isUserNotFound"
          label="First Name"
          label-for="firstName"
          label-cols="2"
        >
          <div>
            <b-form-input
              id="firstName"
              v-model="$v.firstName.$model"
              :state="isValidFirstName"
              debounce="500"
            />
            <b-form-invalid-feedback :state="isValidFirstName">
              First name is required.
            </b-form-invalid-feedback>
          </div>
        </b-form-group>
        <b-form-group
          v-if="isUserNotFound"
          label="Last Name"
          label-for="lastName"
          label-cols="2"
        >
          <div>
            <b-form-input
              id="lastName"
              v-model="$v.lastName.$model"
              :state="isValidLastName"
              debounce="500"
            />
            <b-form-invalid-feedback :state="isValidLastName">
              Last name is required.
            </b-form-invalid-feedback>
          </div>
        </b-form-group>

        <div v-if="isUserNotFound" class="mb-2 text-right">
          <b-button
            variant="primary"
            @click="registerNewMember"
            :disabled="!isRegisterButtonAvailable"
          >
            <b-spinner v-if="isLoading" small></b-spinner>
            Register user and add
          </b-button>
        </div>

        <div v-if="isSameUser" class="mt-2 mb-2">
          You cannot change your own role. To change your role, ask another
          administrator.
        </div>

        <RoleUpdate
          :org-id="organisationId"
          v-model="memberDetails"
          :disabled="isSameUser"
        />
        <div
          class="alert alert-secondary mt-4 mb-0"
          v-if="member && member.hasMfa"
        >
          <b-icon icon="lock-fill" font-scale="2" />
          Multi-factor authentication is <strong>active</strong> for this user.
          You may
          <b-link href="#" @click="resetMfa">reset their device</b-link> to
          enable them to register a new device.
        </div>
      </form>
    </template>
    <template v-slot:modal-footer>
      <div>
        <b-button variant="dark" @click="hide" class="mr-3"> Cancel </b-button>
        <b-button
          @click="save"
          variant="primary"
          :disabled="!isAddButtonAvailable"
        >
          <span v-if="member">Update</span>
          <span v-else>
            <b-spinner v-if="isLoading" small></b-spinner>
            Add
          </span>
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {required, email, requiredIf} from "vuelidate/lib/validators";
import {mapActions, mapGetters} from "vuex";
import RoleUpdate from "../role/RoleUpdate";
import handleApiError from "@/shared/apiErrorUtil";

export default {
  name: "MemberUpdate",
  props: ["modalId", "organisationId", "member"],
  components: {RoleUpdate},
  computed: {
    ...mapGetters("role", {roles: "getRolesWithKey"}),
    ...mapGetters("auth", ["getCurrentUser"]),
    title() {
      return this.member ? "Edit User" : "Add User";
    },
    selectedRole() {
      return this.memberDetails.group;
    },
    isSameUser() {
      return this.email === this.getCurrentUser.email;
    },
    isValidEmail() {
      if (this.$v.email.$dirty) {
        return !this.$v.email.$invalid && this.isNewMember;
      } else {
        return null;
      }
    },
    isRegisterButtonAvailable() {
      return !this.$v.$invalid && this.isAddBtnAvailable;
    },
    isAddButtonAvailable() {
      return (
        !this.$v.$invalid && this.isAddBtnAvailable && !this.isUserNotFound
      );
    },
    isValidFirstName() {
      return !this.$v.firstName.$dirty || !this.$v.firstName.$invalid
        ? null
        : false;
    },
    isValidLastName() {
      return !this.$v.lastName.$dirty || !this.$v.lastName.$invalid
        ? null
        : false;
    },
  },
  data() {
    return {
      email: null,
      memberDetails: {
        group: null,
        fullAccess: false,
        canSubmit: false,
      },
      canSubmit: false,
      isUserNotFound: false,
      isAddBtnAvailable: true,
      firstName: null,
      lastName: null,
      isLoading: false,
      isNewMember: true,
    };
  },
  validations: {
    email: {required, email},
    memberDetails: {
      group: {required},
      fullAccess: {required},
      canSubmit: {required},
    },
    firstName: {
      required: requiredIf("isUserNotFound"),
    },
    lastName: {
      required: requiredIf("isUserNotFound"),
    },
  },
  watch: {
    member: {handler: "initialiseMember"},
    selectedRole: function (newRole) {
      const isAdmin = newRole === "ADMIN";
      if (isAdmin) {
        this.memberDetails.fullAccess = true;
      }
      const maybeSubmit = this.getMaybeSubmitRoles()(
        this.organisationId
      ).includes(newRole);
      if (!maybeSubmit) {
        this.memberDetails.canSubmit = false;
      }
    },
  },
  methods: {
    ...mapActions("orgStore", ["saveMember", "resetMemberMfa", "registerUser"]),
    ...mapGetters("role", ["getMaybeSubmitRoles"]),
    save() {
      if (!this.isAddButtonAvailable) {
        return;
      }
      this.isAddBtnAvailable = false;
      const updateData = {
        isCreate: this.member == null,
        orgId: this.organisationId,
        member: {
          ...this.memberDetails,
          email: this.email,
        },
      };

      this.saveMember(updateData)
        .then(() => this.$emit("member-updated"))
        .then(this.resetModal)
        // if a user activates save with the enter key via the form controls)
        .then(() => this.$bvModal.hide(this.modalId))
        .catch(this.handleError);
    },
    initialiseMember(member) {
      if (member) {
        this.email = member.email;
        this.memberDetails.group = member.group;
        this.memberDetails.fullAccess = member.fullAccess;
        this.memberDetails.canSubmit = member.canSubmit;
      } else {
        //new member, reset modal
        this.resetModal();
      }
    },
    resetModal() {
      this.email = "";
      this.memberDetails.group = null;
      this.memberDetails.fullAccess = false;
      this.memberDetails.canSubmit = false;
      this.isUserNotFound = false;
      this.isAddBtnAvailable = true;
      this.firstName = "";
      this.lastName = "";
      this.isLoading = false;
      this.isNewMember = true;
      this.$v.$reset();
    },
    handleError(error) {
      this.isLoading = false;
      this.isAddBtnAvailable = true;
      const status = error.status || error.response.status;
      if (status === 404) {
        this.isUserNotFound = true; // show error message
        return; // do nothing. error message already exists on form
      }

      if (status === 400 && error.response.data) {
        const errorMessage = error.response.data.message;
        if (errorMessage.includes("user is already a member")) {
          this.isNewMember = false;
          return;
        }
      }

      const h = this.$createElement;
      const msg = h("div", [
        "Unable to create user.",
        h(
          "p",
          "Please try again. If the problem persists, contact service@smartygrants.com.au."
        ),
      ]);
      handleApiError(error, this, [msg]);
    },
    hide() {
      this.$bvModal.hide(this.modalId);
    },
    emailFocus() {
      this.isUserNotFound = false;
      this.isNewMember = true;
    },
    resetMfa() {
      this.$bvModal
        .msgBoxConfirm(
          "Proceeding with this reset will send a confirmation email to the user, requiring them to log in and register a new device for multi-factor authentication.",
          {
            title: "Confirm multi-factor authentication device reset",
            okTitle: "Yes, Go Ahead",
            cancelVariant: "dark",
          }
        )
        .then(proceed => {
          if (proceed) {
            const data = {
              orgId: this.organisationId,
              email: this.email,
            };
            this.resetMemberMfa(data)
              .then(_ =>
                this.$bvToast.toast("Reset request sent successfully.")
              )
              .catch(_ => this.$bvToast.toast("Failed to send reset request."));
          }
        });
    },
    registerNewMember() {
      this.isAddBtnAvailable = false;
      this.isLoading = true;
      const updateData = {
        orgId: this.organisationId,
        member: {
          ...this.memberDetails,
          email: this.email,
          firstName: this.firstName,
          lastName: this.lastName,
        },
      };

      this.registerUser(updateData)
        .then(() => this.$emit("member-updated"))
        .then(this.resetModal)
        // if a user activates save with the enter key via the form controls)
        .then(() => this.$bvModal.hide(this.modalId))
        .catch(this.handleError);
    },
  },
};
</script>
