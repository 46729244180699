<template>
  <div class="mt-4 mb-5" v-show="showPendingMembers">
    <div>The following users have yet to complete their registration</div>
    <b-table
      :items="pendingMembersProvider"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      primary-key="email"
      sort-by="name"
      stacked="md"
      striped
      sort-icon-left
      id="pendingMembers"
    >
      <template v-slot:head(group)>
        Role
        <clickable-icon
          class="btn-xs btn-tip ml-1 mb-1"
          icon="info-circle"
          srLabel="Role Legend"
          variant="primary"
          :scale="1.5"
          @click="showLegend"
        />
      </template>
      <template v-slot:head(fullAccess)>
        Submission Access
        <clickable-icon
          class="btn-xs btn-tip ml-1 mb-1"
          icon="info-circle"
          srLabel="Access Legend"
          variant="primary"
          :scale="1.5"
          @click="showAccessLegend"
        />
      </template>
      <template v-slot:cell(canSubmit)="data">
        <check-icon :show="data.item.canSubmit" />
      </template>
      <template v-slot:cell(actions)="data">
        <clickable-icon
          @click="changeRole(data.item)"
          icon="pencil"
          sr-label="Change Role"
          class="pl-1 pr-1"
        />
        <clickable-icon
          @click="removeRole(data.item)"
          icon="x-circle"
          variant="danger"
          sr-label="Remove User"
          class="pl-1"
          :enabled="isNotCurrentUser(data.item)"
        />
      </template>
    </b-table>
    <Pagination
      :number-of-elements="numberOfElements"
      :total-rows="totalRows"
      :current-page="currentPage"
      :per-page="perPage"
      @update-current-page="updateCurrentPage"
    />
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import handleApiError from "../../shared/apiErrorUtil";
import ClickableIcon from "../interface/ClickableIcon";
import Pagination from "../interface/Pagination";
import CheckIcon from "../interface/CheckIcon";
import {formatDate} from "@/shared/filter";

export default {
  name: "PendingMembers",
  components: {
    ClickableIcon,
    Pagination,
    CheckIcon,
  },
  props: ["organisationId"],
  data() {
    return {
      fields: [
        {key: "name", sortable: true},
        {key: "email", sortable: true},
        {
          key: "joinDate",
          label: "Date Joined",
          sortable: true,
          formatter: formatDate,
        },
        {
          key: "group",
          label: "Role",
          formatter: this.displayGroups,
          sortable: true,
        },
        {
          key: "fullAccess",
          label: "Submission Access",
          formatter: fullAccess => (fullAccess ? "All" : "Limited"),
          class: "force-wrap-7",
          sortable: true,
        },
        {
          key: "canSubmit",
          label: "Can Submit",
          class: "text-center force-wrap-5",
          sortable: true,
        },
        {key: "actions", class: "text-right"},
      ],
      currentPage: 1,
      perPage: 15,
      totalRows: 0,
      numberOfElements: 0,
    };
  },
  computed: {
    showPendingMembers() {
      return this.totalRows >= 0 && this.isAdminOf()(this.organisationId);
    },
  },
  methods: {
    ...mapActions("orgStore", ["fetchPendingMembers"]),
    ...mapGetters("auth", ["isAdminOf", "getCurrentUser"]),
    ...mapGetters("role", ["getNameForGroup"]),
    updateCurrentPage(currentPage) {
      this.currentPage = currentPage;
    },
    pendingMembersProvider(ctx) {
      const sortDir = ctx.sortDesc ? "desc" : "asc";
      const pageRequest = {
        orgId: this.organisationId,
        query: {
          params: {
            page: ctx.currentPage - 1,
            size: ctx.perPage,
            sort: ctx.sortBy + "," + sortDir,
          },
        },
      };

      return this.fetchPendingMembers(pageRequest)
        .then(response => {
          const data = response.data;
          this.totalRows = data.totalElements;
          this.numberOfElements = data.numberOfElements;
          return data.content;
        })
        .catch(error => handleApiError(error, this));
    },
    showLegend() {
      this.$emit("show-role-legend");
    },
    showAccessLegend() {
      this.$emit("show-access-legend");
    },
    changeRole(member) {
      this.$emit("change-role", member);
    },
    removeRole(member) {
      this.$emit("remove-role", member);
    },
    isNotCurrentUser(member) {
      return member.email !== this.getCurrentUser().email;
    },
    displayGroups(group) {
      if (!group || group === "SUBMITTER") {
        return "";
      }
      return this.getNameForGroup()(group);
    },
  },
};
</script>

<style scoped lang="scss">
.btn.btn-xs {
  font-size: x-small;
  z-index: 999;
}
</style>
